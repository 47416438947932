// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import $ from "jquery"
import * as dt from "datatables.net-bs4"
import select2 from "select2"
import Inputmask from "inputmask"
import validation from "jquery-validation"
import moment from "moment"
import mt from "moment-timezone"

Rails.start()
ActiveStorage.start()

/* defining global variable */
window.$ = window.jQuery = $
window.dataTable = dt
window.select2 = select2
window.Inputmask = Inputmask
window.validate = validation
window.moment = moment
window.mt = mt